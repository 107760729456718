const GuestsLabel = ({ persons, pets }) => {
  if (persons === 0 && pets === 0) {
    return "1 Persoon";
  } else if (persons === 0) {
    return `${pets} ${pets === 1 ? "Huisdier" : "Huisdieren"}`;
  } else if (pets === 0) {
    return `${persons} ${persons === 1 ? "Persoon" : "Personen"}`;
  } else {
    return `${persons} ${persons === 1 ? "Persoon" : "Personen"} - ${pets} ${
      pets === 1 ? "Huisdier" : "Huisdieren"
    }`;
  }
};

export default GuestsLabel;
