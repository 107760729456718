import React, { useEffect, useState } from "react";
import { useClickOutside } from "@mantine/hooks";
import { Popover } from "@headlessui/react";
import NcInputNumber from "../nc-input-number/NcInputNumber";
import ClearDataButton from "../shared/button/ClearDataButton";
import GuestsLabel from "../shared/GuestLabel";

const GuestsInput = ({
  defaultValue,
  onChange,
  inputFocus,
  maxPersons = 70,
  maxPets = 10,
  isHomepage = true,
  showPets = true,
  containerClass = "[ nc-hero-field-padding ]",
}) => {
  const containerRef = useClickOutside(() => setShowPopover(false));
  const [personsValue, setPersonsValue] = useState(0);
  const [petsValue, setPetsValue] = useState(0);
  const [showPopover, setShowPopover] = useState(inputFocus === "guests");

  useEffect(() => {
    if (inputFocus === "guests") {
      setShowPopover(true);
    }
  }, [inputFocus]);

  useEffect(() => {
    setPersonsValue(defaultValue.persons || 0);
    setPetsValue(defaultValue.pets || 0);
  }, [defaultValue]);

  return (
    <Popover className="[ nc-flex-1 ] relative flex">
      <>
        <div ref={containerRef}>
          <div
            onClick={() => setShowPopover(true)}
            className={`flex w-full flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none ${containerClass} ${
              inputFocus === "guests"
                ? "rounded-full shadow-2xl dark:bg-neutral-800"
                : ""
            }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="nc-icon-field"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
            <div className="flex-grow">
              <span className="block font-semibold xl:text-lg">
                {isHomepage ? (
                  <>
                    {personsValue || ""}{" "}
                    {personsValue === 1 ? "Persoon" : "Personen"}
                  </>
                ) : (
                  <GuestsLabel persons={personsValue} pets={petsValue} />
                )}
              </span>
              <span className="mt-1 block text-sm font-light leading-none text-neutral-800">
                {isHomepage
                  ? personsValue
                    ? "Personen"
                    : "Gezelschap"
                  : `Personen ${maxPets > 0 ? "en huisdieren" : ""} wijzigen`}
              </span>
              {!!personsValue && showPopover && (
                <ClearDataButton
                  onClick={() => {
                    setPersonsValue(0);
                    setPetsValue(0);
                    setShowPopover(false);
                  }}
                />
              )}
            </div>
          </div>

          {showPopover && (
            <div className="absolute right-0 top-full z-10 mt-3 w-full max-w-sm rounded-3xl bg-white py-5 px-4 shadow-xl dark:bg-neutral-800 sm:min-w-[340px] sm:py-6 sm:px-8">
              <NcInputNumber
                className="w-full"
                defaultValue={personsValue}
                value={personsValue}
                onChange={(value) =>
                  onChange({ persons: value, pets: petsValue })
                }
                max={maxPersons}
                min={1}
                label="Personen"
                desc="Volwassenen en kinderen"
              />
              {showPets ? (
                <NcInputNumber
                  className="mt-6 w-full"
                  defaultValue={petsValue}
                  value={petsValue}
                  onChange={(value) =>
                    onChange({ persons: personsValue, pets: value })
                  }
                  max={maxPets}
                  label="Huisdieren"
                  desc="Aantal huisdieren"
                />
              ) : null}
            </div>
          )}
        </div>
      </>
    </Popover>
  );
};

export default GuestsInput;
