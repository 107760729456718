import { useState } from "react";
import { useRouter } from "next/router";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import dayjs from "dayjs";
import queryObjectFilter from "utils/queryObjectFilter";
import queryString from "query-string";

const ListingSearchForm = () => {
  const router = useRouter();
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [locationInputValue, setLocationInputValue] = useState("");
  const [locationData, setLocationData] = useState({ type: null, value: null });
  const [guestValue, setGuestValue] = useState({});
  const [inputFocus, setInputFocus] = useState(null);

  const handleSearch = (dates, location, guests) => {
    const countryParam = location &&
      location.type === "country_code" && {
        country: location.value,
        location: location.location,
      };
    const latLngParam = location &&
      location.type === "lat_lng" && {
        lat: location.lat,
        lon: location.lon,
        location: location.location,
      };
    const datesParam = dates &&
      dates[0] &&
      dates[1] && {
        date: [
          dayjs(dates[0]).format("YYYY-MM-DD"),
          dayjs(dates[1]).format("YYYY-MM-DD"),
        ],
      };
    const personsParam = guests?.persons && { persons: guests.persons };
    const petsParam = guests?.pets && { pets: guests.pets };

    const searchQuery = {
      ...countryParam,
      ...latLngParam,
      ...datesParam,
      ...personsParam,
      ...petsParam,
    };

    let filterQuery = queryObjectFilter(searchQuery);
    const queryStr = queryString.stringify(filterQuery, {
      arrayFormat: "separator",
      arrayFormatSeparator: "|",
    });
    router.push({
      pathname: "/vakantiehuizen",
      query: queryStr,
    });
  };

  return (
    <form className="relative mt-8 flex w-full flex-col divide-y divide-neutral-200 rounded-3xl bg-white shadow-xl dark:divide-neutral-700 dark:bg-neutral-900 dark:shadow-2xl md:flex-row md:items-center md:divide-y-0 lg:rounded-full">
      <LocationInput
        defaultValue={locationInputValue}
        onChange={(e) => setLocationInputValue(e)}
        setLocationData={setLocationData}
        setInputFocus={setInputFocus}
      />
      <StayDatesRangeInput
        defaultValue={dateRangeValue}
        setDates={setDateRangeValue}
        inputFocus={inputFocus}
        setInputFocus={setInputFocus}
      />
      <GuestsInput
        defaultValue={guestValue}
        onChange={(data) => setGuestValue(data)}
        inputFocus={inputFocus}
      />
      <div className="px-4 py-4 lg:py-0">
        <div
          className="flex h-14 w-full cursor-pointer items-center justify-center rounded-full bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none md:h-16 md:w-16"
          onClick={() => handleSearch(dateRangeValue, locationData, guestValue)}
        >
          <span className="mr-3 md:hidden">Zoeken</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>
    </form>
  );
};

export default ListingSearchForm;
