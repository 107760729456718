import { useState } from "react";
import ListingSearchForm from "./ListingSearchForm";

const HeroSearchForm = ({
  currentTab = "Accommodatie zoeken",
  currentPage,
}) => {
  const tabs = ["Accommodatie zoeken"];
  const [tabActive, setTabActive] = useState(currentTab);

  const Tabs = () => {
    return (
      <ul className="hiddenScrollbar ml-2 flex space-x-5 overflow-x-auto sm:ml-6 sm:space-x-8 md:ml-12 lg:space-x-11">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`flex flex-shrink-0 cursor-pointer items-center text-sm font-medium lg:text-base ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="mr-2 block h-2.5 w-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100" />
              )}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const SearchForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    switch (tabActive) {
      case "Accommodatie zoeken":
        return <ListingSearchForm haveDefaultValue={isArchivePage} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0`}
      data-nc-id="HeroSearchForm"
    >
      <Tabs />
      <SearchForm />
    </div>
  );
};

export default HeroSearchForm;
