import { useState, useEffect, useRef } from "react";
import Script from "next/script";
import { useClickOutside, useLocalStorage } from "@mantine/hooks";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import ClearDataButton from "../shared/button/ClearDataButton";

const popularLocations = [
  {
    type: "lat_lng",
    lat: 52.2387683,
    lon: 5.8322737,
    location: "Veluwe, Nederland",
  },
  {
    type: "lat_lng",
    lat: 52.9476012,
    lon: 6.623058599999999,
    location: "Drenthe, Nederland",
  },
  {
    type: "lat_lng",
    lat: 51.4427238,
    lon: 6.0608726,
    location: "Limburg, Nederland",
  },
  {
    type: "lat_lng",
    lat: 51.4940309,
    lon: 3.8496815,
    location: "Zeeland, Nederland",
  },
];

const LocationInput = ({
  defaultValue,
  onChange,
  setLocationData,
  setInputFocus,
}) => {
  const inputRef = useRef(null);
  const containerRef = useClickOutside(() => setShowPopover(false));
  const [showPopover, setShowPopover] = useState(false);
  const [recentLocationSearchResults, setRecentLocationSearchResults] =
    useLocalStorage({
      key: "recent-location-search-results",
      defaultValue: "",
    });

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "initAutocomplete",
    requestOptions: {
      types: ["(regions)"],
    },
    cache: 7 * 24 * 60 * 60, // a week
    debounce: 300,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue(defaultValue), [defaultValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange && onChange(value), [value]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const saveToRecentSearchResults = (location, recentSearchResults) => {
    if (recentSearchResults === "") {
      setRecentLocationSearchResults(JSON.stringify([location]));
    } else {
      let searchResults = JSON.parse(recentSearchResults);
      const alreadySaved = searchResults.some(
        (result) => result.location === location.location
      );
      if (!alreadySaved) {
        searchResults = [location, ...searchResults];
      }
      setRecentLocationSearchResults(
        JSON.stringify(searchResults.splice(0, 4))
      );
    }
  };

  const handleSelectLocation = (item) => {
    setValue(item.location);
    setLocationData(item);
    setShowPopover(false);
    setInputFocus("arrivalDate");
  };

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    async () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      try {
        setInputFocus("arrivalDate");
        const results = await getGeocode({ address: description });
        const lat = results[0]?.geometry?.location?.lat();
        const lon = results[0]?.geometry?.location?.lng();

        // If selected place is a country
        if (results[0]?.address_components[0]?.types.includes("country")) {
          const countryCode = results[0]?.address_components[0]?.short_name;
          setLocationData({
            type: "country_code",
            value: countryCode,
            location: description,
          });
          saveToRecentSearchResults(
            {
              type: "country_code",
              value: countryCode,
              location: description,
            },
            recentLocationSearchResults
          );
        } else {
          setLocationData({ type: "lat_lng", lat, lon, location: description });
          saveToRecentSearchResults(
            { type: "lat_lng", lat, lon, location: description },
            recentLocationSearchResults
          );
        }
        setShowPopover(false);
      } catch (e) {
        //Todo: Send to Sentry
        console.log("😱 Error: ", e);
      }
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <span
          onClick={handleSelect(suggestion)}
          key={place_id}
          className="flex cursor-pointer items-center space-x-3 px-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-5 sm:py-4"
        >
          <span className="block text-neutral-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 sm:h-6 sm:w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </span>
          <p className="block font-medium text-neutral-700 dark:text-neutral-200">
            {main_text}
            <span className="block text-xs text-neutral-700 dark:text-neutral-200">
              {secondary_text}
            </span>
          </p>
        </span>
      );
    });

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="mt-2 block px-4 text-base font-semibold text-neutral-800 dark:text-neutral-100 sm:mt-0 sm:px-8 sm:text-lg">
          Recente zoekopdrachten
        </h3>
        <div className="mt-2">
          {(recentLocationSearchResults
            ? JSON.parse(recentLocationSearchResults)
            : popularLocations
          ).map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item?.location}
              className="flex cursor-pointer items-center space-x-3 px-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-4 sm:px-8 sm:py-5"
            >
              <span className="block text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 sm:h-6 sm:w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item?.location}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="nc-flex-1.5 relative flex" ref={containerRef}>
      <div
        onClick={() => {
          setShowPopover(true);
          setInputFocus("location");
        }}
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none ${
          showPopover ? "rounded-full shadow-2xl dark:bg-neutral-800" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <input
            className={
              "block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200 xl:text-lg"
            }
            placeholder="Locatie"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            autoFocus={showPopover}
            ref={inputRef}
          />
          <span className="mt-0.5 block text-sm font-light text-neutral-800">
            <span className="line-clamp-1">
              {value ? "Locatie" : "Waar wil je naartoe?"}
            </span>
          </span>
          {value && showPopover && (
            <ClearDataButton onClick={() => setValue("")} />
          )}
        </div>
      </div>

      {showPopover && (
        <div className="absolute left-0 top-full z-40 mt-3 max-h-96 w-full min-w-[300px] overflow-y-auto rounded-3xl bg-white py-3 shadow-xl dark:bg-neutral-800 sm:min-w-[500px] sm:py-2">
          {value ? renderSuggestions() : renderRecentSearches()}
        </div>
      )}

      <Script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDJM-WPOeKWIDsktnoo2TnHIPWGr-6A6J4&libraries=places&callback=initAutocomplete"
        strategy="lazyOnload"
      />
    </div>
  );
};

export default LocationInput;
