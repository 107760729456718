import { useEffect, useState } from "react";
import { RangeCalendar } from "@mantine/dates";
import { useClickOutside, useViewportSize } from "@mantine/hooks";
import ClearDataButton from "../shared/button/ClearDataButton";
import dayjs from "dayjs";

const StayDatesRangeInput = ({
  defaultValue,
  setDates,
  inputFocus,
  setInputFocus,
}) => {
  const { width } = useViewportSize();
  const containerRef = useClickOutside(() => handleClickOutside());
  const [stateDate, setStateDate] = useState(defaultValue);

  useEffect(() => {
    setStateDate(defaultValue);
  }, [defaultValue]);

  const handleClickOutside = () => {
    setInputFocus(null);
  };

  const handleClearData = (field) => {
    switch (field) {
      case "checkIn": {
        return setStateDate([null, null]);
      }
      case "checkOut": {
        return setStateDate([null, null]);
      }

      default:
        break;
    }
  };

  const handleDateFocusChange = (focus) => {
    setInputFocus(focus);
  };

  const onChangeRangeCalendar = (dates) => {
    setDates(dates);
    if (dates[0] && dates[1]) {
      setInputFocus("guests");
    }
  };

  const InputCheckInDate = () => {
    //Temporary fix, because the calendar is not working properly
    //See: https://github.com/mantinedev/mantine/discussions/634#discussioncomment-1993440
    const focused =
      inputFocus === "arrivalDate" || inputFocus === "departureDate";

    return (
      <div
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 ${
          focused ? "rounded-full shadow-2xl dark:bg-neutral-800" : " "
        }`}
        onClick={() => {
          handleDateFocusChange("arrivalDate");
        }}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block font-semibold xl:text-lg">
            {stateDate[0]
              ? dayjs(stateDate[0]).format("ddd DD MMM")
              : "Check in"}
          </span>
          <span className="mt-1 block text-sm font-light leading-none text-neutral-800">
            {stateDate[0] ? "Check in" : "Kies datum"}
          </span>
          {stateDate[0] && focused && (
            <ClearDataButton onClick={() => handleClearData("checkIn")} />
          )}
        </div>
      </div>
    );
  };

  const InputCheckOutDate = () => {
    //Temporary fix, because the calendar is not working properly
    //See: https://github.com/mantinedev/mantine/discussions/634#discussioncomment-1993440
    const focused =
      inputFocus === "departureDate" || inputFocus === "arrivalDate";

    return (
      <div
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 ${
          focused ? "rounded-full shadow-2xl dark:bg-neutral-800" : " "
        }`}
        onClick={() => {
          handleDateFocusChange("departureDate");
        }}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block font-semibold xl:text-lg">
            {stateDate[1]
              ? dayjs(stateDate[1]).format("ddd DD MMM")
              : "Check out"}
          </span>
          <span className="mt-1 block text-sm font-light leading-none text-neutral-800">
            {stateDate[1] ? "Check out" : "Kies datum"}
          </span>
          {stateDate[1] && focused && (
            <ClearDataButton onClick={() => handleClearData("checkOut")} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="[ lg:nc-flex-2 ] relative z-10 flex flex-shrink-0"
      ref={containerRef}
    >
      <div className="relative flex w-full flex-shrink-0 flex-col divide-y divide-neutral-200 border-neutral-200 dark:divide-neutral-700 md:border-l md:border-r lg:flex-row lg:items-center lg:divide-y-0 lg:border-none">
        <InputCheckInDate />
        <InputCheckOutDate />
      </div>

      {(inputFocus === "arrivalDate" || inputFocus === "departureDate") && (
        <div className="absolute left-0 top-full z-40 mt-3 max-h-96 w-full min-w-[300px] overflow-y-auto rounded-3xl bg-white py-3 shadow-xl dark:bg-neutral-800 sm:min-w-[550px] sm:py-2 md:min-w-[400px] lg:min-w-[550px]">
          <div className="flex items-center space-x-3 px-4 py-4 sm:space-x-2 sm:px-5 sm:py-2">
            <RangeCalendar
              value={defaultValue}
              minDate={new Date()}
              onChange={onChangeRangeCalendar}
              amountOfMonths={width > 1023 ? 2 : 1}
              fullWidth
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StayDatesRangeInput;
