const queryObjectFilter = (queryObject) => {
  const filteredQueryObject = {};
  Object.keys(queryObject).forEach((key) => {
    if (queryObject[key] === null) {
      return;
    }
    if (queryObject[key] > 0 || queryObject[key].length > 0) {
      filteredQueryObject[key] = queryObject[key];
    }
  });
  return filteredQueryObject;
};

export default queryObjectFilter;
